@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Noto+Sans+NKo&family=Roboto:ital,wght@1,100&family=Sacramento&family=Solitreo&display=swap");

/* base styles */
* {
  /* targets all elements */
  margin: 0;
  font-family: serif;
  color: #333;
}

.background {
  /*min-height: 500px;*/
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.board-members {
  text-align: left;
  padding-top: 5px;
  line-height:26px;
}

.about-top {
  width: 100%;
  height: 90px;
}

.about-container {
  margin: auto;
  max-width: 80%;
  min-height: 750px;
  
  align-items: center;
  text-align: center;
  color: #000000;
  font-size: 19px;
  background-color: #fcffe0dd;
  /* border-radius: 15px; */
  padding-bottom: 50px;
}

.about-content {
  max-width: 450px;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 6px;
}

.justify {
  text-align: justify;
  padding: 5px;
}

.img-example {
  border: solid;
  border-width: thin;
  min-width: 150px;
  min-height: 150px;
  max-width: 350px;
  max-height: 350px;
  aspect-ratio : 1 / 1;
  margin:auto;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.news-content {
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  /* max-width: 750px; */
  display: flex;
  background-color: #BACD92;
  font-size: 18px;
}

.news-item {
  width: 50%;
  min-height: 240px;
  border: solid;
  text-align: center;
  padding-top: 4px;
  border-width: thin;
}

.news-item h3 {
}

.news-item p {
  padding-top: 4px;
}

.navbar {
  width: 100%;
  /* max-width: 750px; */
  position: fixed;
  margin: 0 0;
  display: flex;
  flex-wrap: wrap;
  padding: 14px;
  box-sizing: border-box;
  align-items: center;
  background-color: #f5dad2;
  font-size: 18px;
  border-bottom: 1px solid #75A47F;
  /* border-radius: 0 0 15px 15px; */
}

.navbar p {
  text-decoration: none;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.navbar p:hover {
  cursor: pointer;
}

.navbar .links {
  /* pushes the links to the right, since navbar flex */
  padding: 4px;
}

.active-nav {
  color:#75A47F;
  font-weight: bold;
}

.inactive-nav:hover {
  color: #BACD92;
  /*font-weight: bold;*/ 
}

.home-link {
  color: #88aa3e;
}

.home-link:hover {
  cursor: pointer;
  color:#75A47F;
  font-weight: bold;
}

.navbar .logo {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  font-weight: bold;
  padding-top: 3px;
  scroll-padding-bottom: 3px;
}

.center {
  display: flex;
  margin: auto;
  align-items: center;
}

.navbar .logo {
  padding-top: 3px;
  scroll-padding-bottom: 3px;
  padding-right: 20px;
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.header {
  padding: 5px;
  padding-top: 15px;
  font-family: Georgia, serif;
}

.footer {
  height: 40px;
  background-color: #75A47F ;
  border-top: 1px solid #BACD92;
}
